import { inject } from '@angular/core';
import { ActivatedRoute, CanActivateFn, Router } from '@angular/router';
import { AuthService } from '../services/auth.service';
import { UserRoleEnum } from '../models/enums/user-role-enum';

export const authGuard: CanActivateFn = (route, state) => {
  const authService = inject(AuthService);
  const router = inject(Router);
  if (
    authService.isAuth &&
    (authService.userRole === UserRoleEnum.Admin ||
      authService.userRole === UserRoleEnum.Updater)
  ) {
    if (
      authService.userRole === UserRoleEnum.Updater &&
      route.url.at(0).path !== 'request-management'
    ) {
      router.navigateByUrl('/request-management');
      return false;
    } else {
      return true;
    }
  } else {
    router.navigateByUrl('/login');
    return false;
  }
};
